import * as React from "react"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { Normalize } from "styled-normalize"

import Footer from "./Footer"

import "../assets/styles/global.css"
import "@fontsource/oswald/400.css"
import "@fontsource/oswald/600.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/graduate/400.css"

import "react-medium-image-zoom/dist/styles.css"
import Navigation from "./Navigation"
import BottomBanner from "./BottomBanner"

const generalTheme = {
  fontOswald: "Oswald",
  fontGraduate: "Graduate",
  customFont2: "Graduate",
  maxWidth: "1100px",
  dropShadow: "drop-shadow(0 8px 8px rgba(0, 0, 0, 0.08))",
  fontHeading: `"Oswald", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  fontText: `"Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  green: "#088413",
  footerBackground: "hsl(0, 0%, 90%)",
}

const theme = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(2.5531914893617023, 75.80645161290323%, 48.627450980392155%)",
    primaryLight: "hsl(2.5531914893617023, 75.80645161290323%, 53%)",
  },
  ...generalTheme,
}

const themeTGS = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(206.8965517241379, 100%, 28.431372549019606%)",
    primaryLight: "hsl(206.8965517241379, 100%, 35%)",
  },
  ...generalTheme,
}

const themeTCGW = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(117.6, 32.05128205128205%, 30.58823529411765%)",
    primaryLight: "hsl(117.6, 32.05128205128205%, 33%)",
  },
  ...generalTheme,
}

const themeKTC = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(0, 0%, 10%)",
    primaryLight: "hsl(0, 0%, 20%)",
  },
  ...generalTheme,
}

const themeDS = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(31.129707112970713, 100%, 46.86274509803921%)",
    primaryLight: "hsl(31.129707112970713, 100%, 48.86274509803921%)",
  },
  ...generalTheme,
}

const newrest = {
  colors: {
    text: "#4e4e4e",
    textLight: "#919191",
    background: "#fcfcfc",
    primary: "hsl(30, 79%, 38%);",
    primaryLight: "hsl(30, 79%, 48%);",
  },
  ...generalTheme,
}

const GlobalStyles = createGlobalStyle`
  body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background: #fafafa;
}

h1, h2, h3 , h4, h5, h6 {
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
`

const StyledMain = styled.main`
  color: ${({ theme }) => theme.colors.text};
  max-width: ${({ theme }) => theme.maxWidth};
  margin: auto;
  padding: 82px 0 0;
  min-height: calc(100vh - 410px);
`

const Layout: React.FC<{}> = ({ children }) => {
  const shopOwner = process.env.GATSBY_SHOP_OWNER

  let currentTheme = theme

  if (shopOwner === "TC Grün-Weiss Walldorf") {
    currentTheme = themeTCGW
  } else if (shopOwner === "Rot-Weiss Walldorf") {
    currentTheme = theme
  } else if (shopOwner === "TGS Walldorf") {
    currentTheme = themeTGS
  } else if (shopOwner === "SKV Mörfelden Volleyball") {
    currentTheme = themeTGS
  } else if (shopOwner === "KTC") {
    currentTheme = themeKTC
  } else if (shopOwner === "newrest") {
    currentTheme = newrest
  } else if (shopOwner === "DeinSign") {
    currentTheme = themeDS
  }

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <Normalize />
        <GlobalStyles />
        <Navigation />
        <StyledMain>{children}</StyledMain>
        <Footer />
        <BottomBanner />
      </ThemeProvider>
    </>
  )
}

export default Layout
